import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const About = () => (
  <Layout>
    <SEO
      title={process.env.GATSBY_LANGUAGE === 'th' ? 'เกี่ยวกับ' : 'About'}
      lang={process.env.GATSBY_LANGUAGE}
    />
    <div className="container">
      <h2>About</h2>
      <p>เราเป็นกลุ่มนักเดินทางที่รักการแบ่งปันประสบการณ์ :)</p>
      <ul>
        <li>
          <a
            href="https://www.facebook.com/travinsidercom/"
            className="facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-facebook"> Facebook</i>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/trav.insider/"
            className="instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-instagram"> Instagram</i>
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UCkevfTLh3MCLQbCyUEv-Z5A"
            className="youtube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-youtube"> Youtube</i>
          </a>
        </li>
      </ul>
      {/* <div
        className="fb-page"
        data-href="https://www.facebook.com/travinsidercom/"
        data-tabs=""
        data-width=""
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="false"
        style={{ float: 'right' }}
      >
        <blockquote
          cite="https://www.facebook.com/travinsidercom/"
          className="fb-xfbml-parse-ignore"
        ></blockquote>
      </div> */}
    </div>
  </Layout>
);

export default About;
